<template>
  <LayoutSuperadmin :sidebarActiveItem="{ value: 'admin_approval' }">
    <template v-slot:title>PENDING RFQS</template>
    <div class="p-2 p-lg-5 h-100 overflow-auto bg-gray-light-4">
      <AppLoading fillSpace v-if="!orders"></AppLoading>
      <div v-else>
        <div class="d-none d-lg-block">
          <AppTable
            class="d-none h-100"
            :headers="header"
            :items="orders.data"
            :total="orders.total"
            :currentPage="orders.current_page"
            :sort="sorts"
            @sort="sortBy"
            @pageChange="pageChange"
          >
            <template v-slot:item="{ shownItem }">
              <!-- RFQ Detail -->
              <td>{{ /** item.rqf_num */ shownItem.po_number || 'N/A' }}</td>
              <!-- Buyer Detail -->
              <td class="text-truncate" style="max-width: 150px">
                {{ shownItem.project_name }}
              </td>
              <!-- Member Name -->
              <td class="d-flex align-items-center">
                <img
                  src="@/assets/pocketyard_logo_black.svg"
                  class="rounded-circle mr-3"
                  width="30"
                  height="30"
                />
                <div style="width: 200px">
                  <p class="m-0 text-truncate">{{ shownItem.full_name }}</p>
                  <p class="m-0 text-gray text-truncate">
                    {{ getCompanyName(shownItem.user) }}
                  </p>
                </div>
              </td>
              <!-- Order Date -->
              <td>
                <span>{{ shownItem.created_at | readableDate(false) }}</span>
                <span class="ml-1 text-gray">
                  {{ shownItem.created_at | readableTime }}
                </span>
              </td>
              <!-- Product Type -->
              <td>
                <!-- <span class="p-2 d-inline-block bg-gray-light-2 text-center"> -->
                Materials and Supplies
                <!-- </span> -->
              </td>
              <td class="text-info">
                <router-link
                  :to="{
                    name: 'OrderAdminApprovalView',
                    params: { id: shownItem.id },
                  }"
                  class="
                    order-table-action
                    text-decoration-none
                    d-flex
                    align-items-center
                  "
                >
                  <AppIcon name="document"></AppIcon>
                  <!-- <p class="m-0 ml-1">View Quotation</p> -->
                </router-link>
              </td>
            </template>
          </AppTable>
        </div>

        <!-- Mobile View -->
        <div class="d-lg-none">
          <AppCard
            v-for="(shownItem, index) in orders.data"
            :key="`order-item-${index}`"
            class="p-3 mb-3"
            @click="
              $router.push({
                name: 'OrderAdminApprovalView',
                params: { id: shownItem.id },
              })
            "
          >
            <div class="d-flex align-items-center justify-content-between">
              <!-- Project Name -->
              <span class="w-50 text-truncate">
                {{ shownItem.project_name }}
              </span>
              <!-- Status -->
              <div>
                <span
                  class="
                    p-2
                    d-inline-block
                    text-center text-caption-2
                    bg-special-light-4
                  "
                >
                  {{ STATUS.REQUEST_PENDING.buyer_text }}
                </span>
              </div>
            </div>
            <div class="d-flex align-items-center">
              <div>
                <AppMediaViewer
                  width="45px"
                  aspectRatio="1/1"
                  class="rounded-circle"
                  :src="
                    shownItem.user.information
                      ? shownItem.user.information.profile_img
                      : ''
                  "
                ></AppMediaViewer>
              </div>
              <div class="ml-2 d-flex flex-column">
                <!-- USer Name -->
                <span class="">
                  {{ shownItem.user.information | userFullName }}
                </span>
                <!-- User Company -->
                <span class="text-body-2">
                  {{ shownItem.user.information | userCompany }}
                </span>
              </div>
            </div>
            <!-- RFQ -->
            <div class="row mt-3">
              <div class="col-6 font-weight-3">RFQ</div>
              <div class="col-6">{{ shownItem.id | rfqFormat }}</div>
            </div>
            <!-- PO NUMBER -->
            <div class="row mt-3">
              <div class="col-6 font-weight-3">PO Number</div>
              <div class="col-6">{{ shownItem.po_number || 'N/A' }}</div>
            </div>
            <!-- End Bidding Date -->
            <div class="row mt-3">
              <div class="col-6 font-weight-3">Bidding Date End</div>
              <div class="col-6">
                {{ shownItem.bidding_end_date | readableDate(false, true) }}
              </div>
            </div>
          </AppCard>

          <AppPagination
            v-if="orders.data"
            hideDetails
            centerPagination
            itemsPerPage="10"
            :currentPage="orders.current_page"
            :total="orders.total"
            @pageChange="pageChange"
          ></AppPagination>
        </div>
      </div>
    </div>
  </LayoutSuperadmin>
</template>

<script>
import placeholder from '@/assets/pocketyard_logo_black.svg';
import AppTable from '@/shared/elements/AppTable.vue';
import AppIcon from '@/shared/elements/AppIcon.vue';
import Orders from '@/shared/api/Orders';
import AppLoading from '@/shared/elements/AppLoading.vue';
import { STATUS } from '@/shared/constants/OrderConstants';
import AppCard from '@/shared/elements/AppCard.vue';
import AppMediaViewer from '@/shared/elements/AppMediaViewer.vue';
import AppPagination from '@/shared/elements/AppPagination.vue';
import _readableDateTime from '@/shared/mixins/_readableDateTime';
import _appApiHelper from '@/shared/mixins/_appApiHelper';
import _rfqFormat from '@/shared/mixins/_rfqFormat';
import LayoutSuperadmin from '@/components/Partial/Layouts/LayoutSuperadmin/LayoutSuperadmin.vue';

export default {
  name: 'BuyerOrderAdminList',

  components: {
    AppTable,
    AppIcon,
    AppLoading,
    AppCard,
    AppMediaViewer,
    AppPagination,
    LayoutSuperadmin,
  },

  mixins: [_readableDateTime, _appApiHelper, _rfqFormat],

  data() {
    return {
      STATUS,
      orders: [],
      placeholder,
      sorts: [{ item: 'order_created_at', orderBy: 'DESC' }],
      currentPage: 1,
    };
  },

  computed: {
    header() {
      return [
        {
          text: 'PO',
          value: 'rqf_num',
          sortable: false,
        },
        {
          text: 'Project Name',
          value: 'project_name',
          sortable: false,
        },
        {
          text: 'Member',
          value: 'member_name',
          sortable: false,
        },
        {
          text: 'Date',
          value: 'date_created',
          desc: this.sorts.length > 0 ? this.isDesc('date_created') : false,
        },
        {
          text: 'Product',
          value: 'product_type',
          desc: this.sorts.length > 0 ? this.isDesc('product_type') : false,
        },
        {
          text: '',
          value: '',
          sortable: false,
        },
      ];
    },
  },

  filters: {
    userFullName(value) {
      if (value && (value.first_name || value.last_name)) {
        return `${value.first_name} ${value.last_name} `;
      } else {
        return 'N/A';
      }
    },
    userCompany(value) {
      if (value && value.company_name) {
        return value.company_name;
      } else {
        return 'N/A';
      }
    },
  },

  methods: {
    getCompanyName(user)
    {
      return user.company?.name
    },
    async fetchOrders(page = 1) {
      const sorts = {};
      this.sorts.forEach((value) => {
        sorts[value.item] = value.orderBy.toLowerCase();
      });

      this.order = null;
      this.orders = (
        await Orders.getOrders({
          params: {
            status: STATUS.REQUEST_PENDING.value,
            page,
            ...sorts,
            fetch_by_company: 1,
          },
        })
      ).data;
    },
    sortBy({ item, desc }) {
      const existingIndex = this.sorts.findIndex(
        (value) => value.item === item
      );
      if (existingIndex < 0) {
        this.sorts.push({ item, orderBy: desc ? 'DESC' : 'ASC' });
      } else {
        this.sorts[existingIndex].orderBy = desc ? 'DESC' : 'ASC';
      }

      this.fetchOrders();
    },
    async pageChange(page) {
      this.pendingOrders = await this.fetchOrders(page);
    },
    isDesc(value) {
      return this.sorts.find((val) => val.item === value)?.orderBy === 'DESC';
    },
  },

  mounted() {
    this.fetchOrders();
  },
};
</script>

<style lang="scss" scoped>
.order-table {
  &-action {
    p {
      // display: none;
      max-width: 0;
      overflow: hidden;
      transition: max-width 0.5s ease-in-out;
      white-space: nowrap;
    }
    &:hover {
      p {
        max-width: 200px;
      }
    }
  }
}
</style>
